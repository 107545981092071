
'use strict';

var _win = $(window);

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};
var $simpleSlider;

var simpleSlider = function() {
    
    $simpleSlider = $('.slider-img-text');
    
    if ($simpleSlider.length) {
        $simpleSlider.each(function (i) {
            if (!$(this).hasClass('slick-initialized')) { 
                $(this).slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: 'progressive',
                    dots: true,
                    centerMode: false,
                    swipe: true,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    arrows: true, 
                    prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow slider-arrow" aria-label="Prev" role="button" style="display: block;" aria-disabled="false">  </button>',
                    nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow slider-arrow" aria-label="Next" role="button" style="display: block;" aria-disabled="false">  </button>',
                    responsive: [{
                        breakpoint: 960,
                        settings: { 
                                infinite: true,
                                swipe: true,
                                arrows: false,
                                
                            }
                        }
                    ],
                    
                });
            }

        });
    }
    $(window).trigger('slick::initialized');
}


$(_win).on('load',function() {
    simpleSlider();
});
